<template>
  <div
    class="know-box box has-text-centered comment-box has-text-weight-semibold">
      <div class="has-text-centered is-underlined know-title">JDoodle For Your Organisation</div>
        <div class="has-text-left">
          <ul>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Do you have any specific compiler requirements?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Do you want to integrate compilers with your website, webapp, mobile app, courses?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Are you looking more features in
                <a class="is-link" target="_blank" href="https://www.jdoodle.com/compiler-api"> JDoodle Plugin </a> and
                <a class="is-link" target="_blank" href="https://www.jdoodle.com/compiler-api"> JDoodle API </a>?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Looking for Multiple Files, Connecting to DB, Debugging, etc.?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Are you building any innovative solution for your students or recruitment?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Want to run JDoodle in-house?</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Custom Domain, White-labelled pages for your institute?</span>
            </li>
          </ul>
        </div>
      <div class="has-text-centered in-service">Contact us at hello@jdoodle.com</div>
    </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'jdoodleForYou',
  data: function () {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

  .know-box {
    margin-top: 2em;
  }

  .know-title {
    font-size: 1.2em;
    font-weight: bold;
  }

  li {
    padding: 0.55em;
  }

  .li-points {
    margin-left: 1em;
    font-size: 0.8em;
    font-weight: 450;
  }

  .in-service {
    font-size: 0.85em;
    padding: 1em;
    font-weight: 450;
  }

</style>
