<template>
  <div>
    <div class="has-text-centered comment-box-title has-text-weight-semibold">
      Your valuable input will help us improve this site <br/>
      please give your comments. Thanks.
    </div>
    <div class="">
      <div class="columns is-mobile">
        <div
          class="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-6-desktop is-offset-3-desktop box comment-box has-text-weight-semibold"
        >
          <div class="other-languages">
            <a class="is-link has-text-weight-bold" @click="showMainMenu">
              Click here
            </a>
            to see the languages currently supported.
          </div>
          <div class="what-next">
            Which language would you like to see next in JDoodle?
          </div>
          <div>
            <div v-show="!surveyForm.success" class="columns">
              <div class="column">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      v-model="surveyForm.comment"
                      v-validate="'required'"
                      :class="{
                        input: true,
                        'is-danger': errors.has('language')
                      }"
                      class="input"
                      data-vv-as="Language"
                      maxlength="50"
                      name="language"
                      placeholder="New Language"
                      type="text"
                    />
                  </div>
                  <div class="control">
                    <button
                      class="button has-text-weight-bold"
                      v-on:click="survey"
                    >
                      Add This
                    </button>
                  </div>
                </div>
                <div>
                  <span
                    v-show="errors.has('language')"
                    class="help is-danger"
                  >{{ errors.first("language") }}</span
                  >
                </div>
              </div>
            </div>
            <div
              v-cloak
              v-show="surveyForm.success"
              class="what-next what-next-thanks"
            >
              Thanks for your Input!
            </div>
            <div
              v-cloak
              v-show="surveyForm.exists"
              class="what-next what-next-thanks"
            >
              {{ this.surveyForm.comment }} is already available at
              <a v-bind:href="surveyForm.existingPath">{{
                  this.surveyForm.existingPath
                }}</a>
            </div>
          </div>

          <div class="what-next">Comments/Suggestions/Inputs...</div>
          <div v-show="!commentForm.success" class="columns">
            <div class="column">
              <textarea
                v-model="commentForm.comment"
                v-validate="'required|min:5'"
                :class="{ input: true, 'is-danger': errors.has('comment') }"
                class="textarea"
                data-vv-as="Comment"
                name="comment"
                rows="6"
                v-on:keyup="checkMax"
              ></textarea>
              <span v-show="errors.has('comment')" class="help is-danger">{{
                  errors.first("comment")
                }}</span>
              <div class="is-size-7 note">
                For direct response, please include your email id in the comment
                <br/>
                or email to
                <a href="mailto:hello@jdoodle.com">hello@jdoodle.com</a>
              </div>
              <button
                class="myButton has-text-weight-bold"
                type="button"
                v-on:click="comment"
              >
                Post Comment
              </button>
            </div>
          </div>
          <div
            v-cloak
            v-show="commentForm.success"
            class="what-next what-next-thanks has-text-centered"
          >
            Thanks for your Input!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'commentBox',
  data: function () {
    return {
      surveyForm: {
        inputError: false,
        comment: null,
        success: false,
        exists: false,
        existingPath: ''
      },
      commentForm: {
        inputError: false,
        comment: null,
        success: false
      }
    }
  },
  methods: {
    checkMax () {
      if (this.commentForm.comment.length > 300) {
        this.commentForm.comment = this.commentForm.comment.substr(0, 1000)
      }
    },
    showMainMenu () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, 'MainMenu')
    },
    survey () {
      this.$validator
        .validate('language', this.surveyForm.comment)
        .then(result => {
          if (result) {
            this.surveyForm.success = true

            let url = '/api/comment'
            $.ajax({
              url: url,
              method: 'post',
              data: { comment: this.surveyForm.comment, isSurvey: true }
            })
              .fail((jqXHR, textStatus, errorThrown) => {
                window.jda.exception(url + ': ' + errorThrown)
              })
              .done(data => {
                if (data.statusCode === 201) {
                  this.surveyForm.exists = true
                  this.surveyForm.existingPath = data.path
                }
              })

            window.jda.pageEvent(window.jda.CATEGORY.GLOBAL, 'survey', '')
          }
        })
    },
    comment () {
      this.$validator
        .validate('comment', this.commentForm.comment)
        .then(result => {
          if (result) {
            this.commentForm.success = true

            let url = '/api/comment'
            $.ajax({
              url: url,
              method: 'post',
              data: { comment: this.commentForm.comment, isSurvey: false }
            }).fail(function (jqXHR, textStatus, errorThrown) {
              window.jda.exception(url + ': ' + errorThrown)
            })
            window.jda.pageEvent(window.jda.CATEGORY.GLOBAL, 'comment', '')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-box-title {
  margin-bottom: 2em;
}

.container {
  margin: 35px auto;
}

.what-next {
  margin: 1em;
}

.what-next-thanks {
  color: green;
}

.what-next-thanks a {
  text-decoration: underline;
}

.what-next-email {
  font-size: 13px;
  color: #696565;
}

.link-dropdown {
  width: 230%;
  margin-left: -65%;
  padding: 5%;
  padding-top: 10px;
  padding-bottom: 35px;
  background: skyblue;
}

.other-languages {
  font-size: 0.8em;
}

.comment-box {
  background: #c9c9cb;
  color: #4a4a4a;
  margin: 0 auto 50px auto;
}

.dark {
  .comment-box {
    background: #2f2d2d;
    color: whitesmoke;
    border-color: whitesmoke;
  }

  .comment-box-title,
  .other-languages,
  .what-next,
  .note {
    color: #6c757e;
  }

  input,
  textarea {
    background: #6c757e;
    border-color: black;
  }
}

.myButton {
  margin-top: 15px;
  padding: 12px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f5f5f5;
  background: #363739;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}
</style>
