<template>
  <div
    class="margin-top-20px margin-bottom-10px has-text-centered">
    <div class="level has-text-centered">
      <div class="level-item store-img">
        <a href='https://play.google.com/store/apps/details?id=com.nutpan.jdoodle_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'  target="_blank">
          <img src="@/assets/images/google-play-badge.png" alt="Get it on Google Play" class="google-store-img">
        </a>
      </div>
      <div class="level-item store-img">
        <a href='https://apps.apple.com/us/app/jdoodle/id1544598494' target="_blank">
          <img src="@/assets/images/Apple_Store_Badge.svg" alt="Get it on Apple Store" class="apple-store-img">
        </a>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'jdoodleMobilePromo',
  data: function () {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.google-store-img {
  height: auto;
  max-width: 250px;
}

.apple-store-img {
  height: auto;
  width: 200px;
}
</style>
