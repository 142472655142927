<template>
  <div class="tree-view-box has-background-white-ter">
    <div class="tree-top-bar">
      <span class="has-text-centered confirm-text float-left" v-if="doodleForm.errorMessage">
        <font-awesome-icon icon="exclamation-circle" class="has-text-danger"/>
        {{doodleForm.errorMessage}}
      </span>
      <span class="float-right">
        <a class="has-text-grey-dark is-small min-button has-text-weight-bold tooltip" data-tooltip="Minimize" @click="$emit('compress')">
          <font-awesome-icon icon="compress"/>
        </a>
      </span>
    </div>
    <div class="tree-view-area">
      <ul class="tree">
        <TreeItem
          class="item"
          :item="treeData"
          :home="treeHome"
          :activeItem="activeItem"
          :libraries="libraries"
          :projectKey="projectKey"
          @item-activated="itemActivated"
          @make-start="makeStart"
          @sync="sync"
          @upload-file="uploadFile"
          @folder-renamed="updateChildrensParent" :robotCheckCount="robotCheckCount"
          @delete-item="deleteItem" @sort-path="sortPath" @item-moved="itemMoved"
          @add-item="addItem" @add-folder="addFolder" @check-robot="checkRobotForChildren"></TreeItem>
      </ul>
    </div>
    <!--<div>
      <pre>{{ valueString }}</pre>
    </div>-->
  </div>
</template>

<script>
import TreeItem from './TreeItem'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import { GURU_EVENTS, IDE_CONST, ROBOT_CONST, SYNC_ACTIONS } from '../../assets/javascript/constants'
import formMixin from '../../assets/javascript/form-mixin'
import { eventBus } from '../../assets/javascript/event-bus'
import recaptchaMixin from '../../assets/javascript/recaptcha-mixin'
// eslint-disable-next-line no-unused-vars
import jqueryFileUpload from 'jquery-simple-upload'

export default {
  name: 'projectTree',
  components: { TreeItem },
  props: ['project', 'activeFile', 'projectKey', 'editableTypes', 'libraries'],
  mixins: [formMixin, recaptchaMixin],
  data: function () {
    return {
      syncInProgress: false,
      activeItem: {},
      robotCheckCount: 0,
      tempSyncData: {
        item: null,
        dirtyAction: null
      },
      robotCheckForChildrenConst: 'ProjectTreeRobotCheckForChildren',
      robotCheckForItselfConst: 'ProjectTreeRobotCheckForItself'
    }
  },
  computed: {
    /*    valueString () {
      if (this.project) {
        return JSON.stringify(this.project.treeData, null, 2)
      }

      return {}
    }, */
    treeData () {
      return (this.project) ? this.project.treeData : {}
    },
    treeHome () {
      return (this.project) ? this.project.home : {}
    }
  },
  created () {
    Vue.use(VuejsDialog)
  },
  watch: {
    activeFile () {
      this.activeItem = this.activeFile
    }
  },
  mounted () {
    document.addEventListener('keyup', (event) => {
      this.activeItem.codeChanged = true
    })

    eventBus.$on(this.robotCheckForChildrenConst, (data) => {
      if (data.result === true) {
        this.robotCheckCount++
      } else {
        this.showErrorMessage(ROBOT_CONST.ROBOT_ERROR)
      }
    })
    eventBus.$on(this.robotCheckForItselfConst, (data) => {
      if (data.result === true) {
        this.syncFileChanged(this.tempSyncData.item, this.tempSyncData.dirtyAction)
      } else {
        this.showErrorMessage('Unable to Sync the file with server!')
      }
    })
  },
  beforeDestroy () {
    eventBus.$off(this.robotCheckForChildrenConst)
    eventBus.$off(this.robotCheckForItselfConst)
  },
  methods: {
    checkRobotForChildren () {
      eventBus.$emit(ROBOT_CONST.VALIDATE_ROBOT_CHECK, { requester: this.robotCheckForChildrenConst })
    },
    getParentName: function (item) {
      let parent = '/'

      if (item.parent && item.parent === '/') {
        parent = item.parent + item.name
      } else if (item.parent) {
        parent = item.parent + '/' + item.name
      }
      return parent
    },
    addFolder (item) {
      let parentName = this.getParentName(item)
      let newFolder = {
        name: '',
        editMode: true,
        parent: parentName,
        markedForDeletion: false,
        children: []
      }
      item.children.push(newFolder)
    },
    itemActivated (item) {
      let editable = false

      for (let type of this.editableTypes) {
        if (item.name.endsWith(type)) {
          editable = true
          break
        }
      }

      if (!editable) {
        this.$dialog.alert('Only ' + this.editableTypes + ' file types can be edited. For other file types, download, edit locally and upload.', {
          html: true,
          okText: 'Close'
        })
        return
      }

      this.activeItem.content = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
      if (!this.activeItem.dirtyActions) {
        this.activeItem.dirtyActions = []
      }

      if (this.activeItem.codeChanged === true) {
        this.activeItem.isDirty = true
        this.activeItem.dirtyActions.push({ action: SYNC_ACTIONS.FILE_CHANGED })
        this.activeItem.codeChanged = false
        this.sync()
      }

      this.activeItem = item
      if (item.content) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(item.content)
      } else {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue('')
      }

      this.$emit('file-changed', item)
    },
    addItem (item) {
      if (item.isPublicLib === true) {
        eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../IdeLibraries'), { libraries: this.libraries })
        return
      }

      let parentName = this.getParentName(item)
      item.children.push({
        name: '',
        editMode: true,
        markedForDeletion: false,
        parent: parentName
      })
    },
    makeStart (item) {
      this.project.home = '/' + item.name
    },
    sortComparator: function () {
      return (a, b) => {
        if (a.children && !b.children) {
          return 1
        } else if (!a.children && b.children) {
          return -1
        }

        return a.name.localeCompare(b.name)
      }
    },
    sortPath (path) {
      let parent = this.findEdge(path)
      parent.children.sort(this.sortComparator())
    },
    findEdge (toPath) {
      let ancestors = ['']

      if (toPath !== '/') {
        ancestors = toPath.split('/')
      }

      let parent = this.project.treeData

      for (let i = 0; i < ancestors.length; i++) {
        if (i === (ancestors.length - 1)) {
          return parent
        } else {
          parent = parent.children[parent.children.findIndex((o) => { return o.name === ancestors[i + 1] })]
        }
      }
      return parent
    },
    showErrorMessage (message) {
      this.doodleForm.errorMessage = message
      this.$_.delay(() => { this.doodleForm.errorMessage = null }, 5000)
    },
    deleteItem (item) {
      if (this.project.home === '/' + item.name) {
        return
      }

      let delMethod = () => {
        let parentEdge = this.findEdge(item.parent)
        parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
      }

      if (item.newFile === true) {
        delMethod()
        return
      }

      this.$dialog
        .confirm('<div class="has-text-centered">' +
          'Are you sure want to delete this ' + ((item.children) ? 'folder' : 'file') + '?</div>', {
          html: true,
          okText: 'Yes',
          cancelText: 'No'
        })
        .then(() => {
          // Sync Actions
          item.isDirty = true
          item.markedForDeletion = true
          if (!item.dirtyActions) {
            item.dirtyActions = []
          }

          item.dirtyActions.push({ action: SYNC_ACTIONS.DELETE })

          this.sync()
        })
        .catch(() => {})
    },
    syncBeforeExecute () {
      if (this.activeItem.codeChanged === true) {
        this.activeItem.isDirty = true
        if (!this.activeItem.dirtyActions) {
          this.activeItem.dirtyActions = []
        }
        this.activeItem.dirtyActions.push({ action: SYNC_ACTIONS.FILE_CHANGED })
        this.activeItem.content = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
        this.activeItem.codeChanged = false
      }

      this.sync()
    },
    sync () {
      if (this.syncInProgress) {
        return
      }

      this.syncInProgress = true

      this.syncWorker(this.project.treeData.children)

      this.syncInProgress = false
    },
    dirtyCheck: function (items) {
      for (let item of items) {
        if (item.isDirty === true) {
          return false
        }

        if (item.children) {
          if (!this.dirtyCheck(item.children)) {
            return false
          }
        }
      }
      return true
    },
    isSyncSuccess () {
      return this.dirtyCheck(this.project.treeData.children)
    },
    syncWorker (items) {
      for (let item of items) {
        if (item.isDirty) {
          if (!item.dirtyActions || item.dirtyActions.length < 1) {
            item.isDirty = false
          }
          for (let dirtyAction of item.dirtyActions) {
            switch (dirtyAction.action) {
              case SYNC_ACTIONS.NEW_ITEM: this.syncNewItem(item, dirtyAction)
                break
              case SYNC_ACTIONS.RENAME: this.syncRename(item, dirtyAction)
                break
              case SYNC_ACTIONS.DELETE: this.syncDelete(item, dirtyAction)
                break
              case SYNC_ACTIONS.ITEM_MOVED: this.syncItemMoved(item, dirtyAction)
                break
              case SYNC_ACTIONS.FILE_CHANGED: this.trySyncFileChanged(item, dirtyAction)
                break
            }
          }
        }

        if (item.children) {
          this.syncWorker(item.children)
        }
      }
    },
    removeDirtyAction: function (item, dirtyAction) {
      item.isDirty = false
      item.dirtyActions.splice(item.dirtyActions.indexOf(dirtyAction), 1)
    },
    syncNewItem (item, dirtyAction) {
      this.executeAPIWitoutValiation({
        url: '/api/projectSync/newItem',
        method: 'post',
        data: { projectKey: this.projectKey, path: item.parent, name: item.name, type: (item.children) ? 'folder' : 'file' },
        successAction: (data) => {
          this.removeDirtyAction(item, dirtyAction)
        },
        failureAction: () => {
          let parent = this.findEdge(item.parent)
          parent.children.splice(parent.children.indexOf(item), 1)
          this.showErrorMessage('Create Failed!')
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'project-tree-new-item',
        jdaLabel: this.language
      })
    },
    syncRename (item, dirtyAction) {
      this.executeAPIWitoutValiation({
        url: '/api/projectSync/rename',
        method: 'post',
        data: { projectKey: this.projectKey, path: item.parent, name: item.name, oldName: dirtyAction.oldName },
        successAction: (data) => {
          this.removeDirtyAction(item, dirtyAction)
        },
        failureAction: () => {
          item.name = dirtyAction.oldName
          this.showErrorMessage('Rename Failed!')
          this.removeDirtyAction(item, dirtyAction)
          this.updateChildrensParent(item)
          this.sortPath(item.parent)
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'project-tree-rename',
        jdaLabel: this.language
      })
    },
    syncDelete (item, dirtyAction) {
      this.executeAPIWitoutValiation({
        url: '/api/projectSync/delete',
        method: 'post',
        data: { projectKey: this.projectKey, path: item.parent, name: item.name },
        successAction: (data) => {
          let parentEdge = this.findEdge(item.parent)
          parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
        },
        failureAction: () => {
          item.markedForDeletion = false
          this.removeDirtyAction(item, dirtyAction)
          this.showErrorMessage('Delete Failed!')
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'project-tree-delete',
        jdaLabel: this.language
      })
    },
    syncItemMoved (item, dirtyAction) {
      this.executeAPIWitoutValiation({
        url: '/api/projectSync/move',
        method: 'post',
        data: { projectKey: this.projectKey, toPath: item.parent, name: item.name, fromPath: dirtyAction.oldPath },
        successAction: (data) => {
          this.removeDirtyAction(item, dirtyAction)
        },
        failureAction: () => {
          this.showErrorMessage('Move Failed!')

          // Move Back
          let oldParent = this.findEdge(dirtyAction.oldPath)
          let currentParent = this.findEdge(item.parent)
          currentParent.children.splice(currentParent.children.indexOf(item), 1)
          oldParent.children.push(item)
          item.parent = dirtyAction.oldPath
          this.sortPath(dirtyAction.oldPath)

          // Reinstansiate old file if any
          if (dirtyAction.replacedItem) {
            currentParent.children.push(dirtyAction.replacedItem)
            this.sortPath(dirtyAction.replacedItem.parent)
          }
          this.updateChildrensParent(item)
          this.removeDirtyAction(item, dirtyAction)
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'project-tree-move',
        jdaLabel: this.language
      })
    },
    trySyncFileChanged (item, dirtyAction) {
      this.tempSyncData.item = item
      this.tempSyncData.dirtyAction = dirtyAction
      eventBus.$emit(ROBOT_CONST.VALIDATE_ROBOT_CHECK, { requester: this.robotCheckForItselfConst })
    },
    syncFileChanged (item, dirtyAction) {
      this.executeAPIWitoutValiation({
        url: '/api/projectSync/updateFile',
        method: 'post',
        data: { projectKey: this.projectKey, parent: item.parent, name: item.name, content: item.content },
        successAction: (data) => {
          this.removeDirtyAction(item, dirtyAction)
        },
        failureAction: (status) => {
          if (status === 403) {
            this.$store.commit('clearRobotCheck')
          }
          this.showErrorMessage('Unable to Sync the file with server!')
        },
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'project-tree-update-file',
        jdaLabel: this.language
      })
    },
    updateChildrensParent (item) {
      for (let child of item.children) {
        if (item.parent === '/') {
          child.parent = '/' + item.name
        } else {
          child.parent = item.parent + '/' + item.name
        }

        if (child.children) {
          this.updateChildrensParent(child)
        }
      }
    },
    isHome (item) {
      let parent = '/'

      if (item.parent && item.parent === '/') {
        parent = item.parent + item.name
      } else if (item.parent) {
        parent = item.parent + '/' + item.name
      }
      return (parent === this.treeHome)
    },
    itemMoved (itemDetails) {
      let itemName = itemDetails.itemName

      let fromPath = itemDetails.fromPath
      let toPath = itemDetails.toPath

      let fromParent = this.findEdge(fromPath)
      let toParent = this.findEdge(toPath)

      if (toParent.isPublicLib) {
        return
      }

      let duplicateFound = false
      let duplicateItem
      let item

      for (let fromChild of fromParent.children) {
        if (fromChild.name === itemName) {
          item = fromChild
          break
        }
      }

      if (this.isHome(item)) {
        this.$dialog.alert("Start/Main file can't be moved, Compiler looks for main method in this folder to execute.", {
          html: true,
          okText: 'Close'
        })
        return
      }

      if (item.isStatic === true) {
        this.$dialog.alert(item.staticMessage, {
          html: true,
          okText: 'Close'
        })
        return
      }

      for (let toChild of toParent.children) {
        if (toChild.name === itemName) {
          duplicateItem = toChild
          duplicateFound = true
          break
        }
      }

      let addDirtyActions = () => {
        item.isDirty = true
        if (!item.dirtyAction) {
          item.dirtyActions = []
        }
      }

      let moveItem = () => {
        fromParent.children.splice(fromParent.children.indexOf(item), 1)
        item.parent = toPath
        toParent.children.push(item)

        if (item.children) {
          this.updateChildrensParent(item)
        }

        this.sortPath(toPath)
        this.sync()
      }

      if (duplicateFound) {
        this.$dialog
          .confirm('<div class="has-text-centered">' +
            'File with same name already exists in this folder? \n do you want to replace?</div>', {
            html: true,
            okText: 'Yes',
            cancelText: 'No'
          })
          .then(() => {
            addDirtyActions()
            toParent.children.splice(toParent.children.indexOf(duplicateItem), 1)
            item.dirtyActions.push({ action: SYNC_ACTIONS.ITEM_MOVED, oldPath: fromPath, replacedItem: duplicateItem })
            moveItem()
          })
          .catch(() => {})
      } else {
        addDirtyActions()
        item.dirtyActions.push({ action: SYNC_ACTIONS.ITEM_MOVED, oldPath: fromPath })
        moveItem()
      }
    },
    uploadFile (fileDetails) {
      let target = fileDetails.event.target
      let item = fileDetails.item
      let itemParent = '/'

      if (!target || !target.files) {
        this.showErrorMessage('Please try again')
        return
      }

      if (item.parent && item.parent === '/') {
        itemParent = '/' + item.name
      } else if (item.parent) {
        itemParent = item.parent + '/' + item.name
      }

      let duplicateFound = false
      let duplicate

      for (let child of item.children) {
        if (child.name === target.files[0].name) {
          duplicateFound = true
          duplicate = child
          break
        }
      }

      let uploadFileToServer = () => {
        let uploadedFile
        $(target).simpleUpload('/api/projectSync/uploadFile', {

          data: { projectKey: this.projectKey, path: itemParent },

          init () {
            uploadedFile = this.files[0].upload.file
          },

          success: (data) => {
            this.doodleForm.errorMessage = null

            let reader = new FileReader()
            reader.onload = (e) => {
              item.children.push({
                name: data.name,
                content: e.target.result,
                editMode: false,
                markedForDeletion: false,
                parent: itemParent
              })
            }
            if (!duplicateFound) {
              reader.readAsText(uploadedFile)
            }
          },

          cancel: () => {
            this.showErrorMessage('Upload Canceled')
          },

          error: (error) => {
            if (error.xhr.status === 403) {
              this.doodleForm.errorMessage = 'Unable to upload, please try again.'
              this.$store.commit('clearRobotCheck')
            } else {
              this.doodleForm.errorMessage = error.message
            }
            this.$_.delay(() => { this.doodleForm.errorMessage = null }, 10000)
          }
        })
      }

      if (duplicateFound) {
        if (duplicate.children) {
          this.$dialog.alert('Folder with same name found! Delete the folder with this file name, and try again.', {
            html: true,
            okText: 'Close'
          })
          target.value = ''
          return
        }
        this.$dialog
          .confirm('<div class="has-text-centered">' +
            'File with same name already exists in this folder? \n do you want to replace?</div>', {
            html: true,
            okText: 'Yes',
            cancelText: 'No'
          }).then(() => {
            uploadFileToServer()
            target.value = ''
          })
          .catch((e) => {
            target.value = ''
          })
      } else {
        uploadFileToServer()
        target.value = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~bulma-tooltip";
  @import "~vuejs-dialog/dist/vuejs-dialog.min.css";

  .tree-view-box {
    border: 1px solid #ddd;
    min-height: 100%;
    padding: 0;
    position: relative;
  }

  .tree-top-bar {
    width: 100%;
    background: #e8e8e8;
    height: 30px;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .min-button {
    padding: 0px 0.25em 0px 0.25em;
    font-size: 1em;
    height: 30px;
    margin-right: 0.2em;
  }

  .tree {
    font-size: 0.85em;
    font-weight: 600;
  }

  .confirm-text {
    font-size: 0.75em;
    font-weight: bold;
  }

  .message-box {
    padding: 0.5em;
    color: white;
  }

  .ide-menu-box {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
    -webkit-transition: height 0.8s ease-in-out;
  }

  .ide-menu-box > .box {
    margin: 1em;
    background: hsl(0, 0%, 96%);
  }

  .ide-menu-box.is-active {
    height: 80px;
  }

  .tree-view-area {
    width: 100%;
    height: calc(100% - 36px);
    position: absolute;
    overflow: scroll;
  }

  .dark {
    .tree-view-area {
      background: black !important;
    }

    .tree-view-box {
      background: black !important;
      border: 1px solid #6c757e;
    }

    .tree-top-bar {
      background: darkgray;
    }

    .tree-item {
      color: lightgray;
    }
  }

</style>
