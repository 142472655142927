<template>
  <li :data-item="item.name" v-if="!item.markedForDeletion" draggable="true" v-on:drop="dropAction"
      v-on:dragover="dragOverAction" droppable="true"
      v-on:dragstart="dragStartAction" :data-folder="isFolder" :data-path="path">
    <div @click="toggle" class="tree-item" v-bind:class="{'active': isActive}">
      <div class="dropdown item-dropdown-menu" v-bind:class="{'is-active' : showMenu}">
        <div class="dropdown-trigger">
          <a class="is-right tree-item-icon item-settings" @click="showMenuItem" v-click-outside="closeMenu">
            <font-awesome-icon icon="ellipsis-h" aria-haspopup="true" aria-controls="dropdown-menu3"/>
          </a>
        </div>
        <div class="dropdown-menu" id="dropdown-menu3" role="menu" v-show="showMenu">
          <div class="dropdown-content has-background-grey-lighter">
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="$emit('make-start', item)" v-if="isHomeCandidate"
               data-newfile="true">
              <font-awesome-icon class="has-text-grey-dark" icon="home"/>&nbsp;&nbsp;Make it as Start File
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="addFile" v-if="isFolder && !isPublicLib"
               data-newfile="true">
              <font-awesome-icon class="has-text-grey-dark" icon="file-alt"/>&nbsp;&nbsp;New File
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="addFile" v-if="isPublicLib"
               data-newfile="true">
              <font-awesome-icon class="has-text-grey-dark" icon="file-alt"/>&nbsp;&nbsp;Manage Libs
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="addFolder" v-if="isFolder && !isPublicLib"
               data-newfile="true">
              <font-awesome-icon class="has-text-grey-dark" icon="folder-plus"/>&nbsp;&nbsp;New Folder
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="$emit('delete-item', item)"
               v-if="!item.isStatic && !isPublicLib">
              <font-awesome-icon class="has-text-grey-dark" icon="trash-alt"/>&nbsp;&nbsp;Delete
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" @click="rename" v-if="!item.isStatic && !isPublicLib">
              <font-awesome-icon class="has-text-grey-dark" icon="file-signature"/>&nbsp;&nbsp;Rename
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" v-if="isFolder && !isPublicLib && uploadFlag">
              <input class="file-input" type="file" name="file" ref="file" @change="tryUploadFile($event)">
              <span>
                <font-awesome-icon class="has-text-grey-dark" icon="file-upload"/>&nbsp;&nbsp;Upload File
              </span>
            </a>
            <a class="is-small min-button has-text-weight-bold dropdown-item" :href="getUrl" target="_blank" v-if="isDownloadable">
              <font-awesome-icon class="has-text-grey-dark" icon="download"/>&nbsp;&nbsp;Download
            </a>
          </div>
        </div>
      </div>
      <span v-if="isFolder">
        <font-awesome-icon class='tree-item-icon' icon="folder-open" v-show="isOpen"/>
        <font-awesome-icon class='tree-item-icon' icon="folder" v-show="!isOpen"/>
      </span>
      <span v-else>
          <font-awesome-icon class="tree-item-icon has-text-danger" icon="home" v-if="isHome"/>
          <font-awesome-icon class='tree-item-icon' icon="file-alt" v-if="!isHome"/>
      </span>
      <span v-if="!item.editMode">&nbsp;&nbsp;{{ item.name }}</span>
      <div class="edit-div" v-if="item.editMode">
        <div class="field has-addons">
          <div class="control">
            <input class="input is-small" name="fileName"
                   v-validate="{sibling: true, max:25, regex: /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+$/}"
                   v-bind:class="{'is-danger':errors.has('fileName')}" v-focus type="text" placeholder="fileName.txt"
                   v-model="name"
                   @keydown.esc="closeEditMode" v-click-outside="clickedOutside" @keydown.enter="changeFileName"/>
          </div>
          <div class="control">
            <a class="button is-small is-success" @click.stop="changeFileName">
              <font-awesome-icon class='has-background-success' icon="check"/>
            </a>
          </div>
          <div class="control">
            <a class="button is-small is-warning" @click.stop="closeEditMode">
              <font-awesome-icon class='has-background-warning' icon="times-circle"/>
            </a>
          </div>
        </div>
      </div>
      <div v-show="errors.has('fileName')" class="help is-danger">{{ errors.first('fileName') }}</div>
    </div>
    <div v-if="isFolder">
      <ul class="dragArea" v-show="isOpen" v-if="!isPublicLib">
        <tree-item
          class="item"
          v-for="(child, index) in item.children"
          :key="index"
          :item="child"
          :home="home"
          :libraries="libraries"
          :siblings="item.children"
          :projectKey="projectKey"
          :robotCheckCount="robotCheckCount"
          @folder-renamed="$emit('folder-renamed', $event)"
          :activeItem="activeItem" @sync="$emit('sync')"
          @item-activated="$emit('item-activated', $event)"
          @add-item="$emit('add-item', $event)"
          @add-folder="$emit('add-folder', $event)"
          @delete-item="$emit('delete-item', $event)"
          @sort-path="$emit('sort-path', $event)"
          @item-moved="$emit('item-moved', $event)"
          @make-start="$emit('make-start', $event)"
          @upload-file="$emit('upload-file', $event)"
          @check-robot="$emit('check-robot')"
          @sort-children="sortChildren"
        ></tree-item>
      </ul>
      <ul class="dragArea" v-show="isOpen" v-if="isPublicLib">
        <li v-for="(lib, index) in libraries" :key="index">
          <font-awesome-icon class='tree-item-icon' icon="file-alt"/>&nbsp;{{lib}}
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import { SYNC_ACTIONS } from '../../assets/javascript/constants'

export default {
  name: 'TreeItem',
  props: ['item', 'activeItem', 'siblings', 'home', 'libraries', 'projectKey', 'robotCheckCount'],
  data () {
    return {
      isOpen: true,
      showMenu: false,
      newFile: true,
      tempName: null,
      editModeStarted: false,
      name: '',
      nameError: false,
      isDragging: false,
      eventOnHold: null,
      uploadFlag: false
    }
  },
  computed: {
    getUrl () {
      return `/api/projectSync/downloadItem?projectKey=${encodeURI(this.projectKey)}&path=${encodeURI(this.item.parent)}&name=${encodeURI(this.item.name)}&type=src`
    },
    isDownloadable () {
      if (!this.isFolder || this.item.children.length > 0) {
        return true
      }
      return false
    },
    isPublicLib () {
      return this.item.isPublicLib === true
    },
    isFolder () {
      if (this.item.children) {
        return true
      }
      return false
    },
    isActive () {
      return this.item === this.activeItem
    },
    ref () {
      return this.item.parent + this.item.name
    },
    isHomeCandidate () {
      return !this.isFolder && this.item.parent === '/' && !this.isHome
    },
    isHome () {
      let parent = '/'

      if (this.item.parent && this.item.parent === '/') {
        parent = this.item.parent + this.item.name
      } else if (this.item.parent) {
        parent = this.item.parent + '/' + this.item.name
      }
      return (parent === this.home)
    },
    path () {
      let parent = '/'

      if (this.item.parent && this.item.parent === '/') {
        parent = this.item.parent + this.item.name
      } else if (this.item.parent) {
        parent = this.item.parent + '/' + this.item.name
      }
      return parent
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  created () {
    this.$validator.extend('sibling', {
      getMessage: field => 'Duplicate Filename',
      validate: (value, [testProp]) => {
        return !this.siblings || value === this.tempName || this.siblings.findIndex((o) => {
          return o.name === value
        }) < 0
      }
    })
  },
  methods: {
    tryUploadFile ($event) {
      this.eventOnHold = $event
      this.$emit('check-robot')
    },
    uploadFile ($event) {
      this.$emit('upload-file', { event: $event, item: this.item })
      this.eventOnHold = null
      this.uploadFlag = false
    },
    dragOverAction (e) {
      e.preventDefault()
      return true
    },
    dropAction (e) {
      this.isDragging = false
      e.preventDefault()
      e.stopPropagation()

      let closestLi = e.target.closest('li')
      let toPath = closestLi.dataset['path']
      let fromPath = e.dataTransfer.getData('dragItemPath')
      let itemName = e.dataTransfer.getData('dragItemName')
      let isItemFolder = e.dataTransfer.getData('dragItemFolder')

      if (toPath === fromPath) {
        return
      }

      let fromPathWithItemName

      if (isItemFolder === 'true') {
        if (fromPath === '/') {
          fromPathWithItemName = '/' + itemName
        } else {
          fromPathWithItemName = fromPath + '/' + itemName
        }
      }

      if ((closestLi.dataset['folder'] === 'true') && !(isItemFolder === 'true' && toPath.startsWith(fromPathWithItemName))) {
        this.$emit('item-moved', { itemName: itemName, fromPath: fromPath, toPath: toPath })
      }
    },
    dragStartAction (e) {
      this.isDragging = true
      e.dataTransfer.setData('dragItemName', this.item.name)
      e.dataTransfer.setData('dragItemPath', this.item.parent)
      e.dataTransfer.setData('dragItemFolder', this.isFolder)

      e.stopPropagation()
    },
    addFile () {
      this.$emit('add-item', this.item)
      this.isOpen = true
    },
    addFolder () {
      this.$emit('add-folder', this.item)
      this.isOpen = true
    },
    clickedOutside (e) {
      if (!e.target.dataset['newfile'] && !this.editModeStarted) {
        this.closeEditMode()
      }

      this.editModeStarted = false
    },
    closeEditMode () {
      this.item.editMode = false
      if (this.newFile) {
        this.item.newFile = true
        this.$emit('delete-item', this.item)
      } else {
        this.item.name = this.tempName
      }
    },
    changeFileName () {
      if (this.errors.has('fileName')) {
        return
      }

      if (!this.name) {
        this.closeEditMode()
      } else {
        // There is a reason for this if statement and the duplication of item name assignment
        if (this.isHome) {
          this.item.name = this.name
          this.$emit('make-start', this.item)
        } else {
          this.item.name = this.name
        }

        this.item.editMode = false
        this.$emit('sort-children')
      }

      // Sync Actions
      this.item.isDirty = true
      if (!this.item.dirtyAction) {
        this.item.dirtyActions = []
      }

      if (this.isFolder) {
        this.$emit('folder-renamed', this.item)
      }

      if (this.newFile) {
        this.item.dirtyActions.push({ action: SYNC_ACTIONS.NEW_ITEM })
        this.newFile = false
      } else {
        this.item.dirtyActions.push({ action: SYNC_ACTIONS.RENAME, oldName: this.tempName })
      }

      this.$emit('sync')
    },
    sortComparator: function () {
      return (a, b) => {
        if (a.children && !b.children) {
          return 1
        } else if (!a.children && b.children) {
          return -1
        }

        return a.name.localeCompare(b.name)
      }
    },
    sortChildren () {
      this.item.children.sort(this.sortComparator())
    },
    showMenuItem (e) {
      this.showMenu = true
      this.uploadFlag = true
    },
    closeMenu () {
      this.showMenu = false
    },
    toggle () {
      if (this.showMenu) {
        return
      }

      if (this.isFolder) {
        this.isOpen = !this.isOpen
      } else {
        this.$emit('item-activated', this.item)
      }
    },
    rename () {
      this.newFile = false
      this.item.editMode = true
      this.editModeStarted = true
      this.name = this.item.name
      this.tempName = this.item.name
    }
  },
  watch: {
    robotCheckCount () {
      if (this.eventOnHold) {
        this.uploadFile(this.eventOnHold)
      }
    }
  }
}

// eslint-disable-next-line no-unused-vars
function preventDragStartDefault (e) {
  e.preventDefault()
}
</script>

<style scoped>
  .item {
    cursor: pointer;
  }

  ul {
    padding-left: 1em;
    line-height: 1.5em;
    list-style-type: none;
  }

  .tree-item {
    padding: 2px;
    white-space: nowrap;
    position: relative;
  }

  .tree-item:hover {
    background: lightgray;
    color: white;
  }

  .tree-item.active {
    background: #55acee;
    color: white;
  }

  .tree-item-icon {
    color: grey;
  }

  .active .tree-item-icon {
    color: white;
  }

  .is-right {
    float: right;
  }

  .item-settings {
    padding-right: 5px;
  }

  a.dropdown-item {
    font-size: 0.9em;
  }

  .edit-div {
    display: inline-block;
    width: 70%;
    margin-left: 10px;
  }

  input {
    background: #e8e8e8;
  }

  .sortable-ghost {
    background: skyblue;
    color: white;
  }

</style>
