<template>
    <div
      class="know-box box has-text-centered comment-box has-text-weight-semibold">
        <div class="has-text-centered is-underlined know-title">Know Your JDoodle</div>
        <div class="has-text-left">
          <ul>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">JDoodle supports 76+ languages with multiple versions - <a class="is-link" @click="showMainMenu"> see all</a>.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">With <a class="is-link" href="https://www.jdoodle.com/compiler-api"> JDoodle APIs</a>, you can execute programs just by making a REST call.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">With <a class="is-link" href="https://www.jdoodle.com/compiler-api"> JDoodle Plugins</a>, you can embed an IDE to your website with just 3 lines of code.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">You can embed the code saved in JDoodle directly into your website/blog - <a class="is-link" target="_blank"
                                                                                                          href="http://blog.nutpan.com/2016/07/run-programs-directly-from-your-blog-or.html"> learn more</a>.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">If you like JDoodle, <a class="is-link" @click="share">please share your love with your friends</a>.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Fullscreen - side-by-side code and output is available. click the "<font-awesome-icon icon="expand"/>" icon near execute button to switch.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Dark Theme available. Click on "<font-awesome-icon icon="ellipsis-h"/>" icon near execute button and select dark theme.</span>
            </li>
            <li><font-awesome-icon icon="dot-circle" class="has-text-grey-dark"/>
              <span class="li-points">Check our <a class="is-link" target="_blank"
                                                                                              href="https://docs.jdoodle.com"> Documentation Page </a> for more info.</span>
            </li>
          </ul>
          <div class="has-text-centered in-service">JDoodle is serving the programming community since 2013</div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'moreInfoBox',
  data: function () {
    return {}
  },
  methods: {
    showMainMenu () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, 'MainMenu')
    },
    share () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, 'SocialIcons')
    }
  }
}
</script>

<style scoped lang="scss">

  .know-box {
    margin-top: 2em;
  }

  .know-title {
    font-size: 1.2em;
    font-weight: bold;
  }

  li {
    padding: 0.4em;
  }

  .li-points {
    margin-left: 1em;
    font-size: 0.8em;
    font-weight: 450;
  }

  .in-service {
    font-size: 0.85em;
    padding: 1em;
    font-weight: 450;
  }

</style>
