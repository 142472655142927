<template>
  <div class="home-container" v-bind:class="{'full-screen': fullScreen, 'dark': isDarkTheme}">
<!--    <div class="is-hidden-print has-text-centered vilambaram-area-top" v-bind:class="{'full-screen-vilambaram': fullScreen}">
      <div id="bsa-zone_1595867813696-0_123456"></div>
    </div>-->
    <div class="has-text-centered title-section is-marginless">
      <h1 class="title is-marginless custom-grey"><span>Online</span> {{langDisplayName}} <span v-if='isCompileLang'>Compiler</span><span> IDE</span></h1>
      <span class="custom-grey multifile-title" v-if="isMultiFileLang">(Advanced IDE)</span>
    </div>
    <div class="has-text-centered ide-info-message custom-grey" v-if="showLinkToMultiFileLang">
      For Multiple Files, Custom Library and File Read/Write, use our new -
      <router-link :to="ideLinkUrl" class="is-underlined custom-grey">Advanced {{langDisplayName}} IDE</router-link>
    </div>
    <div class="has-text-centered ide-info-message custom-grey" v-if="showLinkToSingleFileLang">
      For simple single file programs and faster execution, use -
      <router-link :to="ideLinkUrl" class="is-underlined">Basic {{langDisplayName}} IDE</router-link>
    </div>
    <div class="box no-border-print shared-error" v-if="shareNotFound">
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    </div>
    <div class="box no-border-print code-editor-box" v-if="!shareNotFound" v-bind:class="{'columns': fullScreen}">
      <div class="ide-left"  id="ide-left" v-bind:class="{'split': fullScreen}">
        <div class="has-text-weight-semibold ide-title" v-if="!isMultiFileLang && project && project.name">Project Name: <span class="has-text-danger">{{project.name}}</span></div>
        <div class="is-flex ide-part is-mobile">
          <div id="ide-tree" class="split project-tree no-border-print is-marginless is-paddingless" v-if="isMultiFileLang">
            <div class="has-text-weight-semibold ide-title" v-if="!minimizeTree && project && project.name">Project Name: <span class="has-text-danger">{{project.name}}</span></div>
            <ProjectTree @compress="compressProjectTree" :project="project" @file-changed="fileChanged" :active-file="activeItem" :libraries="javaLibraries"
                         :editable-types="ideMeta.editableTypes" :projectKey="projectKey" v-show="!minimizeTree" ref="projectTree"/>
            <div class="projectBarBox has-text-danger" v-if="minimizeTree">
              <a @click="expandProjectTree" class="has-text-grey-dark">
                <font-awesome-icon icon="folder" class="has-text-grey-dark"/>&nbsp;&nbsp;&nbsp;Project&nbsp;&nbsp;Files
              </a>
            </div>
          </div>
          <div id="ide-code"  class="split is-marginless is-paddingless">
            <div class="active-file-name" v-if="activeItem && isMultiFileLang">{{activeItemName}}</div>
            <div v-bind:class="{'columns is-marginless': isBlockly}">
              <div id="blocklyEditor" style="height: 400px" class="column is-6 blockly-editor no-border-print" v-if="isBlockly"></div>
              <div v-bind:class="{'column is-6 blockly-editor': isBlockly}">
                <div id="code" class="code-editor no-border-print"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ide-right" id="ide-right"  v-bind:class="{'split': fullScreen}">
        <section class="accordions">
          <article class="accordion" v-bind:class="{'is-active' : showAccordion}">
            <div class="accordion-header toggle background-grey-light has-text-weight-semibold" @click="revertAccordion">
              <p><font-awesome-icon icon="chevron-down"/>&nbsp;&nbsp;&nbsp;Execute Mode, Version, Inputs & Arguments</p>
            </div>
            <div class="accordion-body custom-grey">
              <div class="accordion-content is-paddingless">
                <div class="columns is-multiline options-section is-marginless padding-left-none">
                  <div class="column columns is-multiline left-column is-marginless padding-left-none is-print-12" v-bind:class="{'is-6':!interactiveMode, 'is-12':interactiveMode}">
                    <div class="column is-hidden-print" v-bind:class="{'is-12': !interactiveMode, 'is-6':interactiveMode, 'interactive-mode-top':interactiveMode}">
                      <div class="version-interactive-section is-mobile" v-bind:class="{'level': !fullScreen}">
                        <div class="has-text-centered" v-if="!shareNotFound" v-bind:class="{'level-left': !fullScreen}">
                          <div class="field is-narrow">
                            <div class="select is-rounded is-small has-text-weight-bold version-dropdown">
                              <select v-model="versionIndex" @change="versionChanged">
                                <option v-for="(version, index) in versions" v-bind:value="index" v-bind:key="version">
                                  {{version}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="field has-text-centered" v-bind:class="{'level-right': !fullScreen}">
                          <input id="interactiveMode"  v-model="interactiveModeOption"  type="checkbox" name="interactiveMode" class="switch has-text-white"/>
                          <label for="interactiveMode" class="ide-title has-text-weight-semibold">Interactive</label>
                        </div>
                      </div>
                    </div>
                    <div class="column padding-left-none is-print-12" v-bind:class="{'is-12':!interactiveMode, 'is-6':interactiveMode, 'padding-top-none':interactiveMode}">
                      <div v-show="showCommandLine" class="column is-12 is-print-12" v-bind:class="{'padding-top-none':interactiveMode}">
                        <div class="has-text-weight-semibold ide-title"  v-bind:class="{'is-marginless':interactiveMode}">CommandLine Arguments</div>
                        <div class="control">
                          <input v-model="args" class="input" type="text" name="arguments" autocomplete="off"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6 is-print-12" v-show="!interactiveMode">
                    <div class="has-text-weight-semibold ide-title">Stdin Inputs</div>
                    <div class="control">
                      <textarea rows=2 class="textarea" v-model="stdin" name="stdin"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        <div class="level execute-section is-hidden-print">
          <div class="level-item">
            <div class="field is-grouped">
              <p class="control">
                <button class="button is-info has-text-white has-text-weight-bold" @click="tryExecute">
                  <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
                  <span v-if="isCodeExecuting">
                  <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
                </button>
              </p>
              <div id="ideContainer" class="g-recaptcha is-hidden-print captcha-box" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi" data-badge="inline"></div>
              <p class="control" v-if="isCodeExecuting">
                <button class="button has-text-weight-bold" @click="stopExecution">
                  <span><font-awesome-icon icon="stop-circle"/></span>
                </button>
              </p>
              <p class="control" v-if="uploadInProgress">
                <button class="button execute-button" @click="cancelUpload">
                  <span><font-awesome-icon icon="stop-circle"/></span>
                </button>
              </p>
              <p class="control" v-if="!uploadInProgress">
                <label class="button execute-button" id="upload-button">
                  <input class="file-input upload-pointer" type="file" name="file" @change="tryUploadFile($event)" title="">
                  <font-awesome-icon icon="file-upload"/>
                </label>
              </p>
              <p class="control">
                <button class="button has-text-dark has-text-weight-bold"
                        @click="showIdeMenu">
                  <font-awesome-icon icon="ellipsis-h"/>
                </button>
              </p>
              <p class="control is-hidden-mobile" v-if="!isBlockly">
                <button class="button has-text-dark has-text-weight-bold"
                        @click="expand">
                  <font-awesome-icon icon="expand" v-if="!fullScreen"/>
                  <font-awesome-icon icon="compress" v-if="fullScreen"/>
                </button>
              </p>
            </div>
          </div>
        </div>
        <div  v-show="uploadMessage" class="upload-message has-text-centered">
          {{uploadMessage}}
        </div>
        <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
        <div class="has-text-weight-semibold ide-title">Result</div>
        <div class="execute-time level is-mobile is-marginless" v-show="!isCodeExecuting && executionTime">
          <div class="level-left is-marginless is-paddingless" v-show="!interactiveMode"><span>CPU Time: {{this.cpuTime}} sec(s), Memory: {{this.memory}} kilobyte(s)</span></div>
          <div class="is-marginless is-paddingless level-right"><span v-if='isCompileLang'>compiled and&nbsp;</span>executed in {{this.executionTime}} sec(s)</div>
        </div>
        <div>
          <div id="output" class="code-editor no-border-print"></div>
        </div>
        <div v-if="inputFiles && inputFiles.length>0" class="files-generated">
          <div class="has-text-weight-semibold ide-title">Files Uploaded</div>
          <div v-for="file in this.inputFiles" v-bind:key="file">
            <a class="is-link has-text-weight-semibold has-text-dark" @click.prevent="removeFile(file)"><font-awesome-icon icon="times-circle"/></a>&nbsp;&nbsp;
            <a :href="getUrlInput(file)" target="_blank" class="output-files">/uploads/{{file}}</a>
          </div>
        </div>
        <div v-if="outputFiles && outputFiles.length>0" class="files-generated">
          <div class="has-text-weight-semibold ide-title">Files Generated</div>
          <div v-for="file in this.outputFiles" v-bind:key="file">
            <a :href="getUrlOutput(file)" target="_blank" class="output-files">{{file}}</a>
          </div>
        </div>
      </div>
      <div class="has-text-grey-dark ide-info-message advance-ide-hint" v-if="!fullScreen">
        <div>Note: Please check <a href="https://docs.jdoodle.com" target="_blank">our documentation</a>, or <a href="https://www.youtube.com/channel/UCMg38oKj3kRmzksoRDEAs9Q" target="_blank">Youtube channel.</a> for more details</div>
<!--        <ul  v-if="showLinkToSingleFileLang">
          <li>1. You can upload your custom libraries to /lib folder.</li>
          <li>2. For file operations - upload files using upload button <font-awesome-icon icon="file-upload"/>, Files will be upload to /uploads folder. You can read those files in program from /uploads folder. To write a file from your program, write files to '/myfiles' folder. Please note the uploaded files stored in the server only for the current session.</li>
          <li>3. For detailed documentation check - <a href="https://docs.jdoodle.com" target="_blank">Our Documentation</a>, or check our <a href="https://www.youtube.com/channel/UCMg38oKj3kRmzksoRDEAs9Q" target="_blank">Youtube channel.</a></li>
        </ul>
        <ul v-else>
          <li>1. For file operations - upload files using upload button <font-awesome-icon icon="file-upload"/>, Files will be upload to /uploads folder. You can read those files in program from /uploads folder. To write a file from your program, write files to '/myfiles' folder. Please note the uploaded files stored in the server only for the current session.</li>
          <li>2. For detailed documentation check - <a href="https://docs.jdoodle.com" target="_blank">Our Documentation</a>, or check our <a href="https://www.youtube.com/channel/UCMg38oKj3kRmzksoRDEAs9Q" target="_blank">Youtube channel.</a> </li>
        </ul>-->
      </div>
    </div>
<!--    <div id="codefund" class="has-text-centered" v-show="!fullScreen && showEthicalAds"><div data-ea-publisher="jdoodle"  data-ea-type="text" v-bind:class="{'dark  horizontal': isDarkTheme, 'horizontal': !isDarkTheme}"></div></div>-->
<!--    <div class="is-hidden-print has-text-centered vilambaram-area-bottom" v-show="!fullScreen && !showEthicalAds">
      <div id="bsa-zone_1596130146805-9_123456"></div>
    </div>-->
    <!--<div class="is-hidden-print full-screen-vilambaram has-text-centered" >
      <div v-show="vilambaram.showTitle">
        <div class="vilambaram-text">Sponsored: &nbsp;</div>
        <img :src="vilambaram.image" class="carbon-img-size"/>
        <span class="vilambaram-title">&nbsp;{{vilambaram.title}}&nbsp;</span>
        <span class="vilambaram-description"> {{vilambaram.description}} </span>
        <a class="has-text-weight-bold is-link vilambaram-link"  @click="openVilambaram">
          {{vilambaram.callToAction}}
        </a>
      </div>
    </div>-->
<!--    <div class="margin-bottom-10px margin-top-10px has-text-centered">
      <div id="adplus">
      </div>
    </div>-->
    <div class="is-hidden-print ide-info-message has-text-centered margin-top-20px margin-bottom-20px box vote-box" v-if="!fullScreen && totalTimeToVoteLeft>0">
      <div class="vote1">Your JDoodle has been nominated for the University of New South Wales Founders award</div>
      <button class="button is-danger is-medium has-text-weight-bold margin-top-0px margin-bottom-10px" @click="vote">Vote and Support Your JDoodle Now</button>
      <div class="vote2">(It takes only 15 secs to vote)</div>
      <div class="vote-close">Voting closes in</div>
      <div class="vote-timer has-text-weight-bold">{{voteTimer}}</div>
      <div class="vote-thanks">JDoodle really appreciates your support and encouragement</div>
    </div>
    <div class="columns">
      <div class="column"><MoreInfoBox/></div>
      <div class="column"><JdoodleForYou/></div>
    </div>
    <div class="margin-top-20px has-text-centered">
      <div class="has-text-centered">Thanks for using our
        <h1 class="title has-text-centered is-marginless">Online {{ langDisplayName }} <span v-if='isCompileLang'>Compiler</span>
          IDE</h1>
        to execute your program</div>
    </div>
    <div class="columns is-mobile margin-top-10px">
      <div class="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-6-desktop is-offset-3-desktop has-text-centered comment-box has-text-weight-semibold">
        <JdoodleMobilePromo/>
      </div>
    </div>
    <CommentBox class="is-hidden-print"/>
  </div>
</template>

<script>
import CommentBox from '../components/Utils/CommentsBox'
import aceMixin from './../assets/javascript/ide-mixin'
import { eventBus } from './../assets/javascript/event-bus'
import { CMD_LANGUAGES, GURU_EVENTS, IDE_CONST, ROBOT_CONST } from '../assets/javascript/constants'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import { META } from '../assets/javascript/meta'
import ProjectTree from '../components/Utils/ProjectTree'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import Split from 'split.js'
import MoreInfoBox from '../components/Utils/MoreInfoBox'
import JdoodleForYou from '../components/Utils/JDoodleForYou'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import JdoodleMobilePromo from '@/components/Utils/JDoodleMobileAppPromo'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import moment from 'moment'

let ideMenu = () => import('../components/IdeMenu')

export default {
  name: 'ide',
  components: { JdoodleMobilePromo, JdoodleForYou, MoreInfoBox, ProjectTree, DoodleMessage, CommentBox },
  mixins: [aceMixin, formMixin, recaptchaMixin],
  data: function () {
    return {
      interactiveModeOption: false,
      project: null,
      autoSaveOn: false,
      ideMeta: {},
      shareNotFound: false,
      projectKey: false,
      minimizeTree: false,
      activeItem: '',
      fullScreen: false,
      showAccordion: true,
      split: null,
      ideSplit: null,
      buySellUrl: null,
      showEthicalAds: false,
      uploadEvent: null,
      voteTimer: '',
      totalTimeToVoteLeft: 0,
      vilambaram: {
        statlink: '',
        company: '',
        backgroundColor: '',
        callToAction: '',
        description: '',
        logo: '',
        title: '',
        textColor: '',
        ctaTextColor: '',
        showTitle: false
      }
    }
  },
  mounted () {
    this.updateVoteTimer()

    /* if (process.env.NODE_ENV !== 'development' && !window['__PRERENDER_INJECTED']) {
      // this.loadEthical()
      this.showEthicalAds = false
      // this.loadBuySell()
      // this.loadKatchiVilambaram()
      this.loadVuukle()
    } */

    if (this.$store.state.robotChecked === false) {
      this.loadRecaptcha('ideContainer', this.doRoboCheck)
    }

    window.onbeforeunload = function () {
      return 'Are you sure you want to move from this IDE?'
    }

    eventBus.$on('IdeUpdateLibraries', (libs) => {
      this.javaLibraries = libs
    })

    eventBus.$on('IdeChangeFontSize', (size) => {
      localStorage.setItem('JDoodle-Font-Size', size)
      this.fontSize = size
      window.ace.edit(IDE_CONST.CODE_EDITOR).setFontSize(size)

      if (this.fullScreen) {
        $('#code').height(($('.ide-left').height() - 10) + 'px')
        this.codeEditor.resize()
      } else {
        this.codeEditorHeightChangeFunction()
      }
    })

    eventBus.$on('IdeClearProject', () => {
      this.project = null
      this.reset()

      if (this.isMultiFileLang) {
        this.initAdvancedIde('clear')
      }
    })

    eventBus.$on('IdeOpenProject', (data) => {
      this.openIdeProject(data)
    })

    eventBus.$on('IdeProjectSaved', (project) => {
      if (this.isMultiFileLang) {
        let newProject = this.$_.merge(this.project, project)
        this.project = null
        this.project = newProject
      } else {
        this.project = project
      }
    })

    eventBus.$on('IdeStorageChanged', () => {
      this.checkStorage()
    })

    eventBus.$on('IdeAutoSave', (autoSaveOn) => {
      this.autoSaveOn = autoSaveOn
    })

    eventBus.$on('IdeMultiFileSyncAndAct', (actionDetails) => {
      let multifileWaitForSync = (count) => {
        if (this.$refs.projectTree.isSyncSuccess()) {
          actionDetails.action()
        } else if (count > 7) {
          actionDetails.errorForm.inProgress = false
          actionDetails.errorForm.errorMessage = actionDetails.errorMessage
        } else {
          this.$_.delay(multifileWaitForSync, 1000, count + 1)
        }
      }

      this.$refs.projectTree.syncBeforeExecute()
      this.$_.delay(multifileWaitForSync, 1000, 1)
    })

    this.plugOnRequestEventForIde()

    this.checkStorage()
    window.addEventListener('resize', this.onExpand)
    this.initIde()
  },
  beforeDestroy () {
    eventBus.$off('IdeUpdateLibraries')
    eventBus.$off('IdeChangeFontSize')
    eventBus.$off('IdeClearProject')
    eventBus.$off('IdeOpenProject')
    eventBus.$off('IdeProjectSaved')
    eventBus.$off('IdeStorageChanged')
    eventBus.$off('IdeAutoSave')
    eventBus.$off('IdeMultiFileSyncAndAct')
    this.unplugOnRequestEventForIde()
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.project = null
      ACE_UTIL.fixAceeIOSBug()
      if (!window['__PRERENDER_INJECTED']) {
        loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js')
          .then(() => {
            loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
            loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
            if (!to.meta.isShared) {
              vm.$_.delay(vm.initEditors, 500, 1)
            }
          })

        vm.loadBlockly(to.meta.language, vm)
      }

      vm.reset()
      vm.ideMeta = to.meta

      if (vm.ideMeta.isAdvanced === true && !window['__PRERENDER_INJECTED']) {
        vm.initAdvancedIde('init')
      }

      if (vm.$route.query.byPost) {
        vm.executeAPIWitoutValiation({
          url: '/api/doodle/initPostIde',
          method: 'post',
          successAction: (data) => {
            let postAction = (data, count) => {
              if (window.ace) {
                vm.codeEditor.getSession().setValue(data.script)
              } else if (count < 10) {
                vm.$_.delay(postAction, 600, data, count + 1)
              }
            }

            vm.$_.delay(postAction, 600, data, 1)
          },
          markCompleted: false,
          form: vm.doodleForm
        })
      }

      if (vm.ideMeta.isShared) {
        vm.executeAPIWitoutValiation({
          url: '/api/doodle/shareIndex',
          data: { shareId: vm.$route.params.shareId, isInstant: vm.ideMeta.isInstantShare },
          method: 'post',
          successAction: (data) => {
            if (data.project.isMultiFile) {
              let ideMeta = vm.$_.cloneDeep(META[data.project.language])
              ideMeta.isAdvanced = true
              ideMeta.canonicalPath += '-ide'
              ideMeta.isInstantShare = vm.ideMeta.isInstantShare
              vm.ideMeta = ideMeta
            } else {
              vm.ideMeta = META[data.project.language]
            }

            vm.$_.delay(vm.initEditors, 500, 1)

            let postAction = (project, count) => {
              if (window.ace) {
                if (data.project.isMultiFile) {
                  let script = JSON.parse(data.project.script)
                  data.project.treeData = script.treeData
                  data.project.home = script.home
                  data.script = null
                  vm.project = data.project
                  if (window._.isNumber(data.project.versionIndex)) {
                    vm.versionIndex = project.versionIndex
                  }
                  vm.setActiveItem()
                  vm.initAdvancedIde('open-share')
                  for (let node of vm.project.treeData.children) {
                    if (!node.children) {
                      if ('/' + node.name !== vm.project.home) {
                        node.yetToSync = true
                      }
                    } else {
                      vm.markChildrenYetToSync(node)
                    }
                  }
                } else {
                  vm.project = project
                  window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(project.script)
                  if (window._.isNumber(project.versionIndex)) {
                    vm.versionIndex = project.versionIndex
                  }
                }
                vm.javaLibraries = vm.$_.compact(project.libraries.split(' '))

                vm.loadBlockly(vm.ideMeta.language, vm)
              } else if (count < 10) {
                vm.$_.delay(postAction, 600, project, count + 1)
              }
            }

            let postActionBlockly = (project, count) => {
              if (window.Blockly && vm.blocklyWorkspace) {
                vm.project = project
                window.Blockly.serialization.workspaces.load(JSON.parse(data.project.script), vm.blocklyWorkspace, false)
                if (window._.isNumber(project.versionIndex)) {
                  vm.versionIndex = project.versionIndex
                }
              } else if (count < 10) {
                vm.$_.delay(postAction, 600, project, count + 1)
              }
            }

            if (vm.ideMeta.language === 'blockly') {
              vm.$_.delay(postActionBlockly, 600, data.project, 1)
            } else {
              vm.$_.delay(postAction, 600, data.project, 1)
            }
          },
          failureAction: () => {
            vm.shareNotFound = true
          },
          markCompleted: true,
          form: vm.doodleForm
        })
      }
    })
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (window.confirm('Do you want to leave ' + this.ideMeta.langDisplayName + ' IDE?')) {
      next()
    } else {
      next(false)
    }
  },
  computed: {
    activeItemName () {
      if (this.activeItem.parent === '/') {
        return '/' + this.activeItem.name
      } else {
        return this.activeItem.parent + '/' + this.activeItem.name
      }
    },
    showCommandLine () {
      return !CMD_LANGUAGES.includes(this.ideMeta.language)
    },
    isShared () {
      return this.$route.meta.isShared
    },
    interactiveMode () {
      return this.interactiveModeOption === true
    },
    langDisplayName () {
      return this.ideMeta.langDisplayName
    },
    language () {
      return this.ideMeta.language
    },
    versions () {
      return this.ideMeta.versions
    },
    defaultVersion () {
      return this.ideMeta.defaultVersion
    },
    downloadFileName () {
      return 'jdoodle.' + this.ideMeta.fileName
    },
    aceLanguageCode () {
      return this.ideMeta.aceCode ? this.ideMeta.aceCode : this.ideMeta.language
    },
    isCompileLang () {
      return this.ideMeta.isCompile || this.ideMeta.language === 'java'
    },
    isMultiFileLang () {
      return this.ideMeta.isAdvanced === true
    },
    ideLinkUrl () {
      if (this.ideMeta.isPlus && this.isMultiFileLang) {
        return '/' + this.ideMeta.basicPath + '/'
      } else if (this.ideMeta.isPlus && !this.isMultiFileLang) {
        return '/' + this.ideMeta.canonicalPath + '-ide' + '/'
      }

      return ''
    },
    showLinkToMultiFileLang () {
      if (this.ideMeta.isPlus && !this.isMultiFileLang) {
        return true
      }

      return false
    },
    showLinkToSingleFileLang () {
      if (this.ideMeta.isPlus && this.isMultiFileLang) {
        return true
      }

      return false
    },
    isDarkTheme () {
      return this.$store.state.dark || (localStorage.getItem('JDoodle-Theme') === 'dark')
    },
    isBlockly () {
      return this.ideMeta.language === 'blockly'
    }
  },
  methods: {
    vote () {
      window.open('https://unswfounders.typeform.com/peoples-choice', '_blank')
      window.jda.pageEvent(window.jda.CATEGORY.IDE, 'vote', this.language)
    },
    initIde () {
      this.executeAPIWitoutValiation({
        url: '/api/doodle/initIde',
        method: 'post',
        successAction: (data) => {
          this.inputFiles = data.inputFiles
          if (data.robotChecked === true) {
            this.$store.commit('robotCheckDone')
          }
        },
        markCompleted: false,
        form: this.doodleForm
      })
    },
    openIdeProject (data) {
      this.project = null
      this.reset()
      if (this.isMultiFileLang) {
        data.project.treeData = data.project.script.treeData
        data.project.home = data.project.script.home
        data.script = null
      }

      this.project = window._.cloneDeep(data.project)
      if (this.project.libraries) {
        this.javaLibraries = this.project.libraries
      }

      if (this.isMultiFileLang) {
        this.initAdvancedIde('open')
        this.setActiveItem()
        for (let node of this.project.treeData.children) {
          if (!node.children) {
            if ('/' + node.name !== this.project.home) {
              node.yetToSync = true
            }
          } else {
            this.markChildrenYetToSync(node)
          }
        }
      } else {
        if (this.isBlockly) {
          window.Blockly.serialization.workspaces.load(JSON.parse(data.project.script), this.blocklyWorkspace, false)
        } else {
          window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(data.project.script)
        }
      }

      this.versionIndex = data.project.versionIndex
      this.versionChanged()
    },
    compressProjectTree () {
      this.minimizeTree = true
      this.ideSplit.collapse(0)
    },
    expandProjectTree () {
      this.minimizeTree = false
      this.ideSplit.setSizes([25, 75])
    },
    checkStorage () {
      if (localStorage.getItem('JDoodleRecent')) {
        this.isLocalStoreEnabled = true
        this.storage = localStorage
      } else {
        this.storage = sessionStorage
      }
    },
    showIdeMenu () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, ideMenu, {
        language: this.language,
        languageName: this.langDisplayName,
        libraries: this.javaLibraries,
        versions: this.versions,
        project: this.project,
        storage: this.storage,
        versionIndex: this.versionIndex,
        autoSaveOn: this.autoSaveOn,
        downloadFileName: this.downloadFileName,
        aceLanguageCode: this.aceLanguageCode,
        isMultiFileLang: this.isMultiFileLang,
        projectKey: this.projectKey,
        fontSize: this.fontSize,
        blocklyWorkspace: this.blocklyWorkspace
      })
    },
    initAdvancedIde (type) {
      let projectId = (this.project) ? this.project.id : null

      this.executeAPIWitoutValiation({
        url: '/api/projectSync/initIde',
        method: 'post',
        data: { lang: this.ideMeta.language, type: type, projectKey: this.projectKey, projectId: projectId, shareId: this.$route.params.shareId, isInstant: this.ideMeta.isInstantShare },
        successAction: (data) => {
          this.projectKey = data.projectKey
          if (type !== 'open' && type !== 'open-share') {
            this.project = data.project
            this.setActiveItem()
          }

          if (type === 'open' || type === 'open-share') {
            window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(data.homeFile)
          }
        },
        markCompleted: false,
        form: this.doodleForm
      })
    },
    onExpand () {
      if (this.fullScreen) {
        if (this.isMultiFileLang) {
          $('#code').height(($('.ide-left').height() - 25) + 'px')
        } else {
          $('#code').height(($('.ide-left').height()) + 'px')
        }

        this.codeEditor.resize()
      }
    },
    expand () {
      this.fullScreen = !this.fullScreen
      this.$nextTick(() => {
        if (this.fullScreen) {
          this.split = Split(['#ide-left', '#ide-right'], {
            sizes: [60, 40],
            minSize: [100, 100]
          })
          this.codeEditor.getSession().removeListener('change', this.codeEditorHeightChangeFunction)
          this.onExpand()
        } else {
          this.codeEditor.getSession().on('change', this.codeEditorHeightChangeFunction)
          this.codeEditorHeightChangeFunction()

          this.split.destroy()
        }
      })

      /* if (!this.vilambaram.showTitle) {
        this.tryVilambaram(0)
      } */
    },
    setActiveItem () {
      this.activeItem = this.project.treeData.children.find((o) => {
        return '/' + o.name === this.project.home
      })
    },
    ideExecute () {
      this.execute(this.isMultiFileLang)
    },
    getUrlOutput (file) {
      return `/api/projectSync/downloadItem?projectKey=1001&name=${encodeURI(file)}&type=output`
    },
    getUrlInput (file) {
      return `/api/projectSync/downloadItem?projectKey=1001&name=/${encodeURI(file)}&type=input`
    },
    removeFile (file) {
      let url = '/api/projectSync/deleteInputFile'
      let data = { projectKey: 1001, name: file }
      $.ajax({
        type: 'POST',
        url: url,
        data: data
      }).done((data) => {
        this.inputFiles.splice(this.inputFiles.indexOf(file), 1)
      }).fail((jqXHR, textStatus, errorThrown) => {
        // error handling
      })
    },
    fileChanged (item) {
      this.activeItem = item

      if (item.yetToSync) {
        this.executeAPIWitoutValiation({
          url: '/api/projectSync/getFileContent',
          method: 'post',
          data: { projectKey: this.projectKey, name: item.name, path: item.parent },
          successAction: (data) => {
            window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(data.content)
            item.yetToSync = false
          },
          markCompleted: false,
          form: this.doodleForm,
          jdaCategory: window.jda.CATEGORY.IDE,
          jdaEvent: 'project-tree-get-file-content',
          jdaLabel: this.language
        })
      }
    },
    markChildrenYetToSync (root) {
      for (let node of root.children) {
        if (!node.children) {
          node.yetToSync = true
        } else {
          this.markChildrenYetToSync(node)
        }
      }
    },
    postExecuteSuccessHandling () {

    },
    openVilambaram () {
      window.open(this.vilambaram.statlink, '_blank')
      window.jda.pageView('/vilambaram')
    },

    tryVilambaram (count) {
      if (process.env.NODE_ENV === 'development') {
        this.loadVilambaram('', 0)
        return
      }

      this.vilambaram.showTitle = false

      $.ajax({
        url: '/vilambaram/',
        method: 'get'
      }).done((data) => {
        this.loadVilambaram(data, count)
      })
    },
    loadVilambaram (data, count) {
      if (process.env.NODE_ENV === 'development') {
        data = { 'ads': [{ 'active': '1', 'ad_via_link': 'https://www.buysellads.com/?utm_source=jdoodle-com-native\u0026utm_medium=ad_via_link\u0026utm_campaign=in_unit\u0026utm_term=custom', 'backgroundColor': '#FFFFFF', 'backgroundHoverColor': '#FFFFFF', 'bannerid': '404668', 'callToAction': 'Try it for Free', 'company': 'Hotjar', 'creativeid': '414162', 'ctaBackgroundColor': '#f4364c', 'ctaBackgroundHoverColor': '#f4364c', 'ctaTextColor': '#FFFFFF', 'ctaTextColorHover': '#FFFFFF', 'description': 'See how your visitors are really using your website.', 'evenodd': '0', 'external_id': '15072', 'height': '0', 'i': '0', 'identifier': 'f9caa5aae4169ecc8c24a483a3b447db', 'image': 'https://cdn4.buysellads.net/uu/1/42500/1546351188-1538062800-80x80-hotjar-icon.png', 'logo': 'https://cdn4.buysellads.net/uu/1/41334/1546414089-hotjar-logo.png', 'longimp': 'TDE5NKLRTTTTTTDAUWBHBTTTTTT3HE3WZWTTTTTTY4VI6UVTTTTTT', 'longlink': 'TFE5NKLRTTTTTTDAUWBHBTTTTTT3HE3WZWTTTTTTY4VI6UVTTTTTTBDW5JYFC5JGHRSU5R7523UUVBI35VUCCSS2HAXUPRD2ZR7NORZLK2LIBWS727LCLYS35JLW47DI67BUVSPX2MLNVBZM2WONVBZI53NC4OQC5MBCBUSDKHNUTYZQKRUDBII35JWUK7PDP33LKRZW2MGNCSS55JLNAR3RKJLNKYZM2JBT', 'rendering': 'custom', 'statimp': '//srv.buysellads.com/ads/imp/x/GTND42QNCKSD453LCA74YKQMCY7I5K7UCESDEZ3JCWSDC27IFTYICK3KC6BIKK7MF6BI6K3EHJWNBADLKM7UCBZG2Y', 'statlink': '//srv.buysellads.com/ads/click/x/GTND42QNCKSD453LCA74YKQMCY7I5K7UCESDEZ3JCWSDC27IFTYICK3KC6BIKK7MF6BI6K3EHJNCLSIZ', 'textColor': '#3c3c3c', 'textColorHover': '#3c3c3c', 'timestamp': '1563597365', 'title': 'Hotjar', 'width': '0', 'zoneid': '31264', 'zonekey': 'CKYI62JW' }, {}] }
      }

      if (data && data.ads && data.ads.length > 0) {
        let ad = data.ads[0]
        if (ad.description) {
          this.vilambaram.showTitle = true
          this.vilambaram.backgroundColor = ad.backgroundColor
          this.vilambaram.statlink = ad.statlink
          this.vilambaram.company = ad.company
          this.vilambaram.title = ad.title
          this.vilambaram.description = ad.description
          this.vilambaram.callToAction = ad.callToAction
          this.vilambaram.logo = ad.logo
          this.vilambaram.image = ad.image
          this.vilambaram.textColor = ad.textColor
          this.vilambaram.ctaBackgroundColor = ad.ctaBackgroundColor
          this.vilambaram.ctaTextColor = ad.ctaTextColor
        }
      }
    },
    loadGoogleVilambaram () {
      loadScriptInBody.loadScriptInBody('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
      this.$_.delay(() => {
        if (window.adsbygoogle) {
          window.adsbygoogle.push({})
        }
      }, 3000)
    },
    loadKatchiVilambaram () {
      let render = (count) => {
        if (count > 25) {
          return
        }
        if (window.googletag) {
          window.gptadslots = []

          window.googletag = window.googletag || { cmd: [] }
          window.googletag.cmd.push(() => {
            window.responsiveads = window.googletag.sizeMapping()
              .addSize([0, 0], [[320, 100], [320, 50], [300, 100], [300, 50]])
              .addSize([750, 200], [[728, 90]])
              .addSize([975, 200], [[728, 90], [980, 90], [980, 120]])
              .build()

            window.gptadslots.push(window.googletag.defineSlot('/21849154601,21668329608/Ad.Plus-AI-Responsive', [[336, 280]], 'adplus')
              .defineSizeMapping(window.responsiveads)
              .addService(window.googletag.pubads()))

            window.googletag.pubads().setTargeting('site', ['jdoodle.com'])

            window.googletag.enableServices()
          })

          window.googletag.cmd.push(function () { window.googletag.display('adplus') })
        } else {
          this.$_.delay(render, 500, count + 1)
        }
      }

      loadScriptInBody.loadScriptInBody('https://www.googletagservices.com/tag/js/gpt.js').then(() => {
        this.$_.delay(render, 100, 1)
      }).catch(() => {
        console.log('katchi vilambaram failed')
      })
    },
    loadEthical () {
      let render = (count) => {
        if (count > 25) {
          return
        }
        if (window.ethicalads) {
          window.ethicalads.wait.then((placements) => {
            if (!placements || placements.length === 0) {
              this.showEthicalAds = false
            }
          })
        } else {
          this.$_.delay(render, 500, count + 1)
        }
      }
      if (window.ethicalads) {
        window.ethicalads.load_placements()
      } else {
        loadScriptInBody.loadScriptInBody('https://media.ethicalads.io/media/client/ethicalads.min.js').then(() => {
          this.$_.delay(render, 100, 1)
        }).catch(() => {
          this.showEthicalAds = false
          window.jda.pageEvent(window.jda.CATEGORY.GLOBAL, 'vilambaram-blocked', 'ethical')
        })
      }
    },
    loadBuySell () {
      if (!this.buySellUrl) {
        this.buySellUrl = 'https://cdn4.buysellads.net/pub/jdoodle.js?' + (new Date() - new Date() % 600000)
        loadScriptInBody.loadScriptInBody(this.buySellUrl).catch(() => {
          window.jda.pageEvent(window.jda.CATEGORY.GLOBAL, 'vilambaram-blocked', 'buysell')
        })
        window.optimize = window.optimize || { queue: [] }
        window.optimize.queue.push(() => {
          window.optimize.push(['bsa-zone_1596130146805-9_123456', 'bsa-zone_1595867813696-0_123456'])
        })
      }
    },
    loadVuukle () {
      loadScriptInBody.loadScriptInBody('https://cdn.vuukle.com/platform.js').catch(() => {
        console.log('unable to load vuukle')
      }).then(() => {
        console.log('vuukle loaded')
      })
    },
    revertAccordion () {
      this.showAccordion = !this.showAccordion
    },
    tryUploadFile (event) {
      this.uploadEvent = event
      this.callViaCaptcha(this.uploadFile, () => { this.doodleForm.errorMessage = ROBOT_CONST.ROBOT_ERROR })
    },
    updateVoteTimer () {
      let total = moment.utc('2022-11-10 12:59:59').diff(moment.utc(), 'seconds')
      this.totalTimeToVoteLeft = total
      let hours = Math.floor(total / 3600)
      total = total % 3600
      let mins = Math.floor(total / 60)
      let sec = total % 60

      this.voteTimer = hours + ':' + mins.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + sec.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

      if (this.totalTimeToVoteLeft > 0) {
        window._.delay(this.updateVoteTimer, 1000)
      }
    },
    uploadFile () {
      if (this.inputFiles.length >= this.maxUploads) {
        this.doodleForm.errorMessage = 'Maximum upload limit reached.'
        window._.delay(() => { this.doodleForm.errorMessage = null }, 5000)
        return
      }

      if (this.uploadEvent.target.files[0].size >= this.maxFileSize) {
        this.doodleForm.errorMessage = 'File exceeds the acceptable size.'
        window._.delay(() => { this.doodleForm.errorMessage = null }, 5000)
        return
      }

      let vm = this
      $(this.uploadEvent.target).simpleUpload('/api/projectSync/uploadInputFile', {
        data: { projectKey: 1001 },

        start: function (file) {
          vm.currentUpload = this.upload
          vm.uploadInProgress = true
        },

        progress: (progress) => {
        },

        success: (data) => {
          this.inputFiles = data.inputFiles
          this.uploadMessage = 'Upload successful'
          window._.delay(() => { this.uploadMessage = null }, 5000)
          this.uploadInProgress = false
        },

        cancel: () => {
          this.uploadMessage = 'Upload Canceled'
          window._.delay(() => { this.uploadMessage = null }, 5000)
          this.uploadInProgress = false
        },

        error: (error) => {
          if (error.xhr.status === 403) {
            this.uploadMessage = 'Unable to upload, please try again.'
            this.clearRobotCheckStatus()
          } else {
            this.uploadMessage = error.message
          }

          window._.delay(() => { this.uploadMessage = null }, 10000)
          this.uploadInProgress = false
        }

      })
    },
    cancelUpload () {
      this.currentUpload.cancel()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
  @import "./../assets/style/ide";
  @import "../../node_modules/bulma-accordion/dist/css/bulma-accordion.min.css";

  #code {
    font-size: 12px;
    font-weight: 500;
  }

  .home-container {
    margin: auto;
    width: 95%;
  }

  .code-editor-box {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-bottom: 0.1rem;
    padding: 0.25rem;
  }

  .ide-title, .switch[type=checkbox]+label.ide-title {
    font-size: 0.9em;
  }

  .column {
    padding-top: 0.15em;
    padding-bottom: 0.10em;
  }

  @media only screen and (max-width: 769px) {
    .columns, .column {
      margin: 0;
      padding: 0;
    }

    .code-editor-box {
      padding: 0;
    }
  }

  @media only screen and (min-width: 769px) {
    .left-column {
      margin-top: 0.7em;
    }
  }

  .flex-right {
    justify-content: flex-end;
  }

  .flex-left {
    justify-content: flex-start;
  }

  .add-libs-div {
    margin-top: 5px;
  }

  .options-section {
    margin-top: 1em;
  }

  .more-button {
    background: #fa745e;
  }

  .execute-section {
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  #ide-more {
    margin-top: -0.3em;
  }

  .padding-top-none {
    padding-top: 0 !important;
  }

  .padding-left-none {
    padding-left: 0 !important;
  }

  .interactive-mode-top {
    margin-top: 0.75em;
  }

  .shared-error {
    margin-top: 3em;
  }

  .project-tree {
    min-height: 100%;
  }

  .projectBarBox {
    writing-mode: vertical-lr;
    font-size: 0.85em;
    font-weight: 600;
    margin-top: 10px;
  }

  .blockly-editor #code {
    margin-bottom: 0.1rem;
    min-height: 400px;
  }

  #code.blockly-editor {
    min-height: 400px;
  }

  .box-min {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 5%;
    background: #e8e8e8;
    height: 140px;
  }

  .code-editor-min {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 95%;
  }

  .accordion-header {
    font-size: 0.85em;
  }

  .active-file-name {
    padding: 0.3em;
    font-size: 0.75em;
    font-weight: bold;
  }

  .multifile-title {
    font-size: 0.85em;
    font-weight: 500;
  }

  .output-files {
    font-size: 0.85em;
    text-decoration: underline;
    font-weight: bolder;
  }

  .files-generated {
    margin-top: 10px;
  }

  .ide-info-message {
    font-size: 0.8em;
  }

  .advance-ide-hint {
    margin-top: 5px
  }

  .vilambaram-area-top {
    max-height: 95px;
    min-height: 95px;
    display: block;
    width: 100%;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    overflow: hidden;
  }

  .vilambaram-area-bottom {
    max-height: 95px;
    min-height: 95px;
    display: block;
    width: 100%;
    overflow: hidden;
  }

  @media only screen and (min-width: 0px) and (min-height: 0px) {
    div[id^="bsa-zone_1595867813696-0_123456"], div[id^="bsa-zone_1596130146805-9_123456"] {
      min-width: 320px;
      min-height: 50px !important;
    }
  }
  @media only screen and (min-width: 640px) and (min-height: 0px) {
    div[id^="bsa-zone_1595867813696-0_123456"], div[id^="bsa-zone_1596130146805-9_123456"] {
      min-width: 728px;
      min-height: 90px !important;;
    }
  }

  .select:not(.is-multiple):not(.is-loading)::after  {
    border-color: black;
  }

  .switch[type=checkbox]:checked+label::before, .switch[type=checkbox]:checked+label:before {
    background: #607d8b;
  }

  #codefund {
    margin-top: 0.25em;
    margin-bottom: 1em;
  }

  .version-interactive-section {
    padding-left: 10px;
    padding-right: 15px;
  }

  .ide-right {
    margin-top: 0.25em;
  }

  #ide-code {
    width: 100%;
  }

  .codfund-fallback {
    font-weight: bold;
    font-size: 0.85em;
  }

  .vilambaram-text {
    display: inline-block;
    font-size: 0.8125rem;
    line-height: 1.7;
  }

  .vilambaram-title, .vilambaram-call-for-action{
    font-weight: bold;
  }

  .vilambaram-description {
    font-size: 0.88em;
  }

  .carbon-img-size {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .vilambaram-link {
    font-size: 0.85em;
    text-decoration: none !important;
    color: #3498db !important;
  }

  .full-screen-vilambaram {
    margin-bottom: 20px;
    text-decoration: none !important;
    color: #6c757e;
    line-height: 1.7;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .full-screen {

    #codefund {
      position: fixed;
      top: 0;
      left: 0;
      height: 40px;
      z-index: 900;
      width: 100%;
      background: #fafafa !important;
      margin: 0;
      padding: 10px;
    }

    .full-screen-vilambaram {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 95px;
      max-height: 95px !important;
      z-index: 900;
      width: 100%;
      background: #fafafa !important;
      margin: 0;
      padding: 0px;
    }

    .code-editor-box {
      position: fixed;
      top: 0px;
      left: 0;
      height: 100%;
      z-index: 900;
      width: 100%;
      margin: 0;
      padding: 0px;
      border: none;
      border-radius: 0;
    }

    #code {
      border-radius: 0;
      margin: 0;
      padding: 0;
    }

    .ide-right {
      margin-top: 10px;
      margin-right: 10px;
      overflow: scroll;
    }

    .ide-left {
      position: relative;
    }

    .ide-part {
      margin-left: 5px;
    }

    .version-interactive-section div {
      padding: 5px;
    }

  }

  .custom-grey {
    color: #4a4a4a;
  }

  .background-grey-light {
    background: #b5b5b5 !important;
  }

  .upload-message {
    font-weight: bold;
    font-size: 14px;
  }

  .dark {

    #code {
      font-size: 12px;
      font-weight: bold;
    }

    .custom-grey, .title, .ide-title, select, input, textarea {
      color: #6c757e;
    }

    .code-editor-box, select, .accordion-content, input, textarea{
      background: black;
    }

    .background-grey-light {
      background: #4a4a4a !important;
    }

    .accordion-header {
      color: black;
    }

    .accordions .accordion .accordion-header+.accordion-body {
      color: #6c757e;
    }

    .select:not(.is-multiple):not(.is-loading)::after {
      border-color: #6c757e;
    }

    .full-screen #codefund, .full-screen .full-screen-vilambaram {
      background: black !important;
    }

    .code-editor-box, .code-editor, .accordions .accordion .accordion-header+.accordion-body, select, input, textarea, button, .button {
      border: 1px solid #6c757e;
    }

    .full-screen .code-editor-box {
      border: none;
    }

    button.has-text-white, .button.has-text-white {
      color: black !important;
    }

    button, .button {
      background: #6c757e;
    }

    .switch[type=checkbox]+label:after {
      background: navajowhite;
    }

    .active-file-name, .advance-ide-hint {
      color: #6c757e;
    }

    .execute-time {
      color: lightblue;
    }
  }

  .vote-box {
    background-color: gainsboro;
  }

  .vote-normal {

  }

  .vote1 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3c763d;
  }

  .vote11 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0077b5;
  }

  .vote2 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .vote-thanks {
    font-size: 20px;
    font-weight: 600;
  }

  .vote-close {
    font-size: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .vote-timer {
    font-weight: bold;
    font-size: 30px;
    color: red;
  }
</style>
